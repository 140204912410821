import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import CreatePurchaseRecordsLogistic from './create_purchase_records_logistic';
import PurchaseRecordsLogisticQueryDrawerForm from './purchase_records_logistic_query_form';
import PurchaseRecordsLogisticList from './purchase_records_logistic_list';
import PurchaseRecordsLogisticQueryParams from './purchase_records_logistic_query_params_display';
// PurchaseRecordsLogistic 入口组件

const PurchaseRecordsLogisticComponent = () => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [createPurchaseRecordsLogisticVisible, setCreatePurchaseRecordsLogisticVisible] = useState(false);

  const queryHandle = (currentRequestParams) => {
    // 查询操作
    reqParams.current = { ...currentRequestParams };
    // 触发重新读取数据
    setReloadListCount(reloadListCount + 1)
    console.log('search...');
  };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  return (
    <div>
      <Row>
        <Col span={16}>
          <Space>
            <h4>订单快递</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
            <Tooltip title="搜索">
              <Button onClick={() => setDrawerVisible(true)} shape="circle" icon={<SearchOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={8}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => navigate('/sendexpressaccount')}>快递帐号设置</Button>
            {/* <Button style={{ float: 'right' }} type="link" onClick={() => setCreatePurchaseRecordsLogisticVisible(true)}>新建</Button> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}><PurchaseRecordsLogisticQueryParams paramsObj={reqParams.current}></PurchaseRecordsLogisticQueryParams></Col>
      </Row>
      <PurchaseRecordsLogisticQueryDrawerForm
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        queryHandle={queryHandle}
      ></PurchaseRecordsLogisticQueryDrawerForm>

      <PurchaseRecordsLogisticList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
      ></PurchaseRecordsLogisticList>

      <CreatePurchaseRecordsLogistic
        visible={createPurchaseRecordsLogisticVisible}
        setVisible={setCreatePurchaseRecordsLogisticVisible}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></CreatePurchaseRecordsLogistic>
    </div>
  )
};

export default PurchaseRecordsLogisticComponent;
