import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Divider, Space } from 'antd';
import ImageZoom from './image_zoom';

// 通用 div 样式
const containerCommomStyle = {
  width: '100%',
  height: '100%',
  padding: '0.1rem',
  margin: '0.2rem 0.1rem',
  /* flex 布局 */
  display: 'flex',
  /* 实现垂直居中center, 左-left, 右-right */
  alignItems: 'center',
  /* 实现水平居中 */
  justifyContent: 'left',
};

const imageContainerStyle = {
  width: '80%',
  height: 180,
  // border: '1px solid red',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}

const handleContainerStyle = {
  ...containerCommomStyle,
  position: 'relative',
  // position: 'absolute',
  /* 实现垂直居中center, 左-left, 右-right */
  alignItems: 'center',
  /* 实现水平靠右 */
  // justifyContent: 'right',
  width: '100%',
  height: '100%'
};

const sourcePriceNoThroughStyle = { color: '#FF3300', fontSize: '1rem' }
const sourcePriceThroughStyle = { ...sourcePriceNoThroughStyle, textDecoration: 'line-through', color: '#E0E0E0' };

const ListsItem = ({ listsIndex, url, productTitle, productEffects, sourcePrice, salePrice, clickDetailHandle, customButtons, rowHeight }) => {
  // 列表 行组件
  // item={url: '', product_title: '', productEffects: '', sourcePrice: 30, salePrice: 20}
  const [showSourcePrice, setShowSourcePrice] = useState(true);
  const [sourcePriceLineThrough, setSourcePriceLineThrough] = useState(sourcePriceThroughStyle);
  const [showSalePrice, setShowSalePrice] = useState(true);

  // outContainerStyle
const outContainerStyle = {
  border: '0.5px solid #F0F0F0',
  height: rowHeight,
  width: '100%',
  backgroundColor: '#FCFCFC',
  fontFamily: 'sans-serif',
  maxWidth: '600px',
  padding: '0.5rem 0.2rem'
};

  useEffect(() => {
    setShowSourcePrice(sourcePrice > 0);
    let sourcePriceLineThroughStyle = showSalePrice ? sourcePriceThroughStyle : sourcePriceNoThroughStyle;
    setSourcePriceLineThrough(sourcePriceLineThroughStyle);
  }, [sourcePrice, salePrice, showSalePrice]);

  useEffect(() => {
    setShowSalePrice(salePrice > 0);
  }, [salePrice]);

  const priceContainerStyle = {
    ...containerCommomStyle,
    /* 实现垂直居中center, 左-left, 右-right */
    alignItems: 'left',
  };

  const salePriceStyle = {
    fontFamily: 'sans-serif',
    color: "#FF3300",
    display: showSalePrice ? "inline" : "none"
  }

  const imageComponent = (<Col span={8}><div style={imageContainerStyle}><ImageZoom src={url} square={true} /></div></Col>)
  return (
    <div style={outContainerStyle}>
      <Row gutter={16}>
        {url.length > 5 ? imageComponent : null}
        {/* <Col span={8}>
          <div style={imageContainerStyle}>
            {url.length > 5 ? (<ImageZoom src={url}></ImageZoom>) : null}
          </div>
        </Col> */}

        <Col span={16}>
          <Row><Col span={24}>
            <div style={{ width: '100%', height: 'auto', padding: '0.5rem 0.2rem' }}>
              <h3>{productTitle}</h3>
            </div>
          </Col></Row>
          <Row>
            <Col span={24}>
              <div style={{ width: '100%', height: 'auto', padding: '0.2rem' }}>
                <p>{productEffects}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div style={priceContainerStyle}>
                <Space>
                  {showSourcePrice ? <p style={sourcePriceLineThrough}>{`¥${sourcePrice}`}</p> : null}
                  <h3 style={salePriceStyle}>{`¥${salePrice}`}</h3>
                </Space>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={handleContainerStyle}>
            <div style={{ float: 'left', width: 'auto', height: 'auto', padding: '0.2rem 0.5rem' }}>
              {customButtons.map((item, index) => {
                return (
                  <Button key={index.toString()} onClick={() => item.onClick(listsIndex)} type="link">{item.buttonLabel}</Button>
                )
              })}
              <Button onClick={() => clickDetailHandle(listsIndex)} type="link">详情</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Divider></Divider>
    </div>
  )
}

ListsItem.defaultProps = {
  listsIndex: 0,
  url: '',
  productTitle: '',
  productEffects: '',
  sourcePrice: 0,
  salePrice: 0,
  clickDetailHandle: (listsIndex) => console.log(listsIndex),
  customButtons: [],    // 自定义按钮[{buttonName: 'click1', onClick: index => console.log(index)}, ]
  rowHeight: 240,
};

export default ListsItem;
