import React from 'react';
import { Select } from 'antd';
import customConfig from '../../custom_config';

// custom select 组件

const { Option } = Select;

const CustomSelect = ({ options, width, value, onChange, mode }) => {
  return (
    <Select style={{ width: width }} onChange={onChange} value={value} mode={mode} allowClear>
      {options.map((obj, index) => {
        return <Option
          key={index.toString()}
          value={obj.value}
        >{obj.label}</Option>
      })}
    </Select>
  )
}

CustomSelect.defaultProps = {
  options: [],  // [{value: '值', label: '显示名称'}]
  width: customConfig.selectComponentWidth,
  value: null,
  onChange: (value) => { console.log(value) },
  mode: null,
}

export { CustomSelect };
