import React from 'react';
import { sendAxiosRequest } from '../apis/sendRequest';

// 自定义 Select 组件的request请求, ～～～～～～ 根据需要重写 ～～～～～～

const expressCompanyRequestGet = () => {
  // 快递公司编号 Get 请求
  return new Promise(function (resolve, reject) {
    const api = 'kdniao-admin/v1/kdniaoexpresscompanycodes';
    const method = 'get';
    const reqParams = {};
    const reqObject = {};
    sendAxiosRequest(api, method, reqParams, reqObject)
      .then(respObj => {
        resolve(respObj);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

const categoryRequestGet = () => {
  // 分类 Get请求
  return new Promise(function (resolve, reject) {
    const api = 'category-admin/v1/category';
    const method = 'get';
    const reqParams = {};
    const reqObject = {};
    sendAxiosRequest(api, method, reqParams, reqObject)
      .then(respObj => {
        resolve(respObj);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

export {
  expressCompanyRequestGet,
  categoryRequestGet
}
