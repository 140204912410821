import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import PurchaseRecordsLogisticComponent from '../components/purchase_records_logistic';

const LogisticPage = () => (
  <SiteLayout>
    <SEO title="Logistic" />
    <PurchaseRecordsLogisticComponent></PurchaseRecordsLogisticComponent>
  </SiteLayout>
)

export default LogisticPage;
