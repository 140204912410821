import React, { useState } from 'react';
import { Drawer } from 'antd';

import { CustomForm } from '../base_components/form_component';

import {
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formGetRequestParamsKeys,
  formInitialObj
} from './purchase_records_logistic_columns_label_config';
// 列表查询 form

const PurchaseRecordsLogisticQueryDrawerForm = ({ visible, setVisible, queryHandle }) => {
  const [initFormCount, setInitFormCount] = useState(0);

  const closeDrawer = () => {
    // 关闭查询抽屉
    setInitFormCount(initFormCount + 1);
    setVisible(false);
  };

  const onFinish = (formObj) => {
    // 提交查询 根据具体情况更新 fromObj
    // ~~~~~~~ 具体情况更新 fromObj ~~~~~~
    let copyFormObj = { ...formObj };
    console.log(copyFormObj);
    queryHandle(copyFormObj);
    setTimeout(() => {
      closeDrawer();
    }, 50);
  };

  return (
    <Drawer
      title="销售记录查询"
      width="100%"
      height="400px"
      placement="top"
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={null}
    >
      <CustomForm
        columns={formGetRequestParamsKeys}
        formKeyLabelObj={formKeysLabelsObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={() => setVisible(false)}
        showDeleteButton={false}
        formInitialObj={formInitialObj}
        initFormCount={initFormCount}
      ></CustomForm>
    </Drawer>
  );
};

PurchaseRecordsLogisticQueryDrawerForm.defaultProps = {
  visible:false,
  setVisible: (booleanValue) => { console.log(booleanValue); },
  queryHandle: (queryParamsObj) => { console.log(queryParamsObj); }
};

export default PurchaseRecordsLogisticQueryDrawerForm;
