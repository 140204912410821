import React, { useEffect, useState } from 'react';
import { Form, Button, Space, Input } from 'antd';

import customConfig from '../../custom_config';
// import { CircularIoncButton } from './button_components/button_icon_components';

// Form 组件

const formCommonStyle = customConfig.formCommonStyle;
const styleFormLayout = formCommonStyle.layoutForm;
const styleFormItemLayout = formCommonStyle.formItemLayout;
const styleFormTailItemLayout = formCommonStyle.tailFormItemLayout;

const formInputStyle = {width: 260};

const FormItem = ({formKey, formKeyLabelObj, disabledKeys, updateFormObj, rulesObj, formKeySelectComponentsObj, passwordKeys, showLabelName=true}) => {
  // 没有按钮的 form item
  const disabled = disabledKeys.includes(formKey);  // 是否可以编辑输入框
  const currentLabel = formKeyLabelObj[formKey];
  const currentInputValue = updateFormObj[formKey];
  return (
    <Form.Item { ...styleFormItemLayout }
      name={formKey}
      label={showLabelName ? currentLabel : ''}
      initialValue={currentInputValue}
      rules={rulesObj[formKey] ? rulesObj[formKey] : []}
    >
      {Object.keys(formKeySelectComponentsObj).includes(formKey)
        ? formKeySelectComponentsObj[formKey]
        : (passwordKeys.includes(formKey)
          ? <Input.Password style={formInputStyle} />
          : <Input style={formInputStyle} placeholder={`请输入 ${currentLabel}`} disabled={disabled} />)}
    </Form.Item>
  );
};

const FormItemExistsHandleButton = ({formKey, formKeyLabelObj, disabledKeys, updateFormObj, rulesObj, formKeySelectComponentsObj, passwordKeys, formKeysItemButtonObj}) => {
  // 有按钮的 form item
  const tips = formKeysItemButtonObj[formKey].buttonTips;
  const onClickMethod = (e) => {
    console.log(e);
    try {
      formKeysItemButtonObj[formKey].clickMethod();
    } catch (err) {
      console.log(err);
    }
  };

  const currentLabel = formKeyLabelObj[formKey];
  return (
    <Form.Item {...styleFormItemLayout} label={currentLabel}>
      <FormItem
        formKey={formKey}
        formKeyLabelObj={formKeyLabelObj}
        disabledKeys={disabledKeys}
        updateFormObj={updateFormObj}
        rulesObj={rulesObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        passwordKeys={passwordKeys}
        showLabelName={false}
      ></FormItem>
      {/* <CircularIoncButton
        tips={tips}
        onClick={onClickMethod}
      ></CircularIoncButton> */}
      <Button type='link' onClick={onClickMethod}>{tips}</Button>
    </Form.Item>
  )
};

const CustomForm = ({
  columns,
  disabledKeys,
  notShowKeys,
  formKeyLabelObj,
  formKeySelectComponentsObj,
  formKeysItemButtonObj,
  onFinish,
  cancelHandle,
  deleteHandle,
  showDeleteButton,
  updateFormObj,
  formInitialObj,
  initFormCount,
  rulesObj,
  passwordKeys,
}) => {
  const [form] = Form.useForm();

  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  useEffect(() => {
    // formInitialObj 变化 重新给form赋值
    // console.log(updateFormObj);
    form.setFieldsValue(updateFormObj);
    setButtonIsLoading(false);
  }, [updateFormObj]);

  useEffect(() => {
    // initFormCount 变化 重新给form赋初始化值
    if (initFormCount > 0) {
      form.setFieldsValue(formInitialObj);
      setButtonIsLoading(false);
    }
  }, [initFormCount]);

  const clickSubmit = (values) => {
    // 提交按钮先让 button 不可点击
    setButtonIsLoading(true);
    onFinish(values);
  };

  const clickDelete = () => {
    // 提交删除先让 button 不可点击
    setButtonIsLoading(true);
    deleteHandle()
  };

  // const getFormFieldsValue = () => {
  //   // 获取表单的所有值
  // }

  return (
    <Form {...styleFormLayout} form={form}
      layout="horizontal" onFinish={clickSubmit} name="adminer_info_edit">
      {columns.map((formKey, index) => {
        if (notShowKeys.includes(formKey)) {
          return null;
        }
        if (Object.keys(formKeysItemButtonObj).includes(formKey)) {
          return (<FormItemExistsHandleButton
              key={index.toString()}
              formKey={formKey}
              formKeyLabelObj={formKeyLabelObj}
              disabledKeys={disabledKeys}
              updateFormObj={updateFormObj}
              rulesObj={rulesObj}
              formKeySelectComponentsObj={formKeySelectComponentsObj}
              passwordKeys={passwordKeys}
              formKeysItemButtonObj={formKeysItemButtonObj}
            ></FormItemExistsHandleButton>)
        } else {
          return <FormItem
            key={index.toString()}
            formKey={formKey}
            formKeyLabelObj={formKeyLabelObj}
            disabledKeys={disabledKeys}
            updateFormObj={updateFormObj}
            rulesObj={rulesObj}
            formKeySelectComponentsObj={formKeySelectComponentsObj}
            passwordKeys={passwordKeys}
          ></FormItem>
        }
      })}
      <Form.Item {...styleFormTailItemLayout}>
        <Space>
          <Button loading={buttonIsLoading} disabled={buttonIsLoading} type="primary" htmlType="submit">提交</Button>
          <Button onClick={cancelHandle}>取消</Button>
          {showDeleteButton ? <Button loading={buttonIsLoading} disabled={buttonIsLoading} onClick={clickDelete}>删除</Button> : null}
        </Space>
      </Form.Item>
    </Form>
  )
};

CustomForm.defaultProps = {
  columns: [],
  disabledKeys: [],
  notShowKeys: [],
  formKeyLabelObj: {},
  formKeySelectComponentsObj: {},
  formKeysItemButtonObj: {},
  onFinish: (values) => { console.log(values); },
  cancelHandle: () => { console.log('Cancel Handle'); },
  deleteHandle: () => { console.log('Delete Handle'); },
  showDeleteButton: false,
  updateFormObj: {},
  formInitialObj: {},
  initFormCount: 0,
  rulesObj: {},
  passwordKeys: [],
  getFieldsValueHandle: values => console.log(values),
};

// 单行表单 (效果不好，弃用)
const CustomSingleRowForm = ({
  columns,
  disabledKeys,
  notShowKeys,
  formKeyLabelObj,
  formKeySelectComponentsObj,
  // formKeysItemButtonObj,
  onFinish,
  onFinishButtonTips,
  cancelHandle,
  showCancelButton,
  deleteHandle,
  showDeleteButton,
  updateFormObj,
  formInitialObj,
  initFormCount,
  rulesObj,
  passwordKeys,
  getFieldsValueHandle
}) => {
  const [form] = Form.useForm();

  const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 8,
    },
  };

  useEffect(() => {
    // formInitialObj 变化 重新给form赋值
    // console.log(updateFormObj);
    form.setFieldsValue(updateFormObj);
  }, [updateFormObj]);

  useEffect(() => {
    // initFormCount 变化 重新给form赋初始化值
    form.setFieldsValue(formInitialObj);
  }, [initFormCount]);

  const FormItem = ({formKey}) => {
    // 没有按钮的 form item
    const disabled = disabledKeys.includes(formKey);  // 是否可以编辑输入框
    const currentLabel = formKeyLabelObj[formKey];
    const currentInputValue = updateFormObj[formKey];
    
    return (
      <Form.Item
        name={formKey}
        initialValue={currentInputValue}
        rules={rulesObj[formKey] ? rulesObj[formKey] : []}
      >
        {Object.keys(formKeySelectComponentsObj).includes(formKey)
          ? formKeySelectComponentsObj[formKey]
          : (passwordKeys.includes(formKey)
            ? <Input.Password style={formInputStyle} />
            : <Input style={formInputStyle} placeholder={`请输入 ${currentLabel}`} disabled={disabled} />)}
      </Form.Item>
    );
  };

  return (
    <Form {...layout} form={form}
      layout="inline"
      onFinish={onFinish}
      name="adminer_info_edit">
      {columns.map((formKey, index) => {
        if (notShowKeys.includes(formKey)) {
          return null;
        }
        return <FormItem formKey={formKey} key={index.toString()}></FormItem>
      })}
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">{onFinishButtonTips}</Button>
          {showCancelButton ? <Button onClick={cancelHandle}>取消</Button> : null}
          {showDeleteButton ? <Button onClick={deleteHandle}>删除</Button> : null}
        </Space>
      </Form.Item>
    </Form>
  )  
};
CustomSingleRowForm.defaultProps = {
  columns: [],
  disabledKeys: [],
  notShowKeys: [],
  formKeyLabelObj: {},
  formKeySelectComponentsObj: {},
  // formKeysItemButtonObj: {},
  onFinish: (values) => { console.log(values); },
  onFinishButtonTips: '确定/修改',
  cancelHandle: () => { console.log('Cancel Handle'); },
  showCancelButton: false,
  deleteHandle: () => { console.log('Delete Handle'); },
  showDeleteButton: false,
  updateFormObj: {},
  formInitialObj: {},
  initFormCount: 0,
  rulesObj: {},
  passwordKeys: [],
  getFieldsValueHandle: values => console.log(values),
};

export { CustomForm, CustomSingleRowForm };
