import React from 'react';

import AxiosRequest from '../apis';
import { apiLists } from './purchase_records_logistic_columns_label_config';

// PurchaseRecordsLogistic 的request请求

const PurchaseRecordsLogisticRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // PurchaseRecordsLogistic Get请求
  const api = apiLists[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsLogisticRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const PurchaseRecordsLogisticRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // PurchaseRecordsLogistic post请求
  const api = [].concat(apiLists)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsLogisticRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const PurchaseRecordsLogisticRequestPut = ({ requestCount, reqObject, responseDataHandle, id }) => {
  // PurchaseRecordsLogistic put请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsLogisticRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const PurchaseRecordsLogisticRequestDelete = ({ requestCount, responseDataHandle, id }) => {
  // PurchaseRecordsLogistic delete请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsLogisticRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

export {
  PurchaseRecordsLogisticRequestGet,
  PurchaseRecordsLogisticRequestPost,
  PurchaseRecordsLogisticRequestPut,
  PurchaseRecordsLogisticRequestDelete
};
